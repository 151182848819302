/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import { StoreInfoContext } from "entrypoint/shared";
import type { Node as ReactNode } from "react";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
};

const HomeHero = ({ children }: Props) => {
  const {
    content: {
      homeview: content,
    },
  } = useContext(StoreInfoContext);

  return (
    <div
      className={cn(styles.hero, "awardit-homeHero")}
    >
      <picture className={styles.image}>
        <source
          srcSet={content.heroImageSmall ?? ""}
          media={`(max-width: ${parseInt(styles.tiny, 10) - 1}px)`}
        />
        <source
          srcSet={content.heroImageLarge ?? ""}
          media={`(min-width: ${parseInt(styles.tiny, 10)}px)`}
        />
        <img
          alt=""
          src={content.heroImageLarge ?? ""}
        />
      </picture>
      <div className={styles.content}>
        <Wrapper className="awardit-homeHeroContent">
          {content.heroIcon && (
            <div className={styles.heroIcon}>
              <img
                alt=""
                src={content.heroIcon}
              />
            </div>
          )}
          {content.heroHeading && (
            <h1>{content.heroHeading}</h1>
          )}
          {/* eslint-disable react/no-danger */}
          {content.heroDescription && (
            <p
              dangerouslySetInnerHTML={{ __html: content.heroDescription }}
            />
          )}
          {/* eslint-enable react/no-danger */}
          {content.heroButtonLink && content.heroButtonText &&
          <Button
            to={content.heroButtonLink}
            variant="primary"
            size="medium"
          >
            {content.heroButtonText}
          </Button>
          }
          {children}
        </Wrapper>
      </div>
    </div>
  );
};

export default HomeHero;
