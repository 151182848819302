/* @flow */

import type { AffiliatesListItem } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import Pixel from "components/Pixel";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";

type Props = {
  affiliate: AffiliatesListItem,
};

const AffiliateCard = ({ affiliate: { id, name, title, image } }: Props) => {
  const { routes } = useContext(StoreInfoContext);
  const gaContext = useContext(AnalyticsContext);

  const gaRegister = () => {
    gaContext.registerContentClick(`Affiliates: ${name}`, id.toString());
  };

  return (
    <Link
      className={styles.block}
      to={{
        pathname: ((routes.earnView && routes.earnView.url) ?? "") + "/" + id,
        state: { hint: {
          id, name, title, image, links: [],
        } },
      }}
      onClick={gaRegister}
    >
      <div className={styles.top}>
        {image && <img className={styles.image} src={image} alt={title} loading="lazy" />}
        <Pixel className={styles.imagePixel} />
      </div>
      <div className={styles.bottom}>
        <p className={styles.name}>{name}</p>
        {/* eslint-disable react/no-danger */}
        <p
          className={styles.info}
          dangerouslySetInnerHTML={{ __html: title ?? "" }}
        />
        {/* eslint-enable react/no-danger */}
      </div>
    </Link>
  );
};

export const DummyAffiliateCard = () => {
  return (
    <div className={cn(styles.block, styles.dummy)}>
      <div className={styles.top}>
        <Pixel className={styles.imagePixel} />
      </div>
      <div className={styles.bottom}>
        <p />
        <p />
      </div>
    </div>
  );
};

export default AffiliateCard;
