/* @flow */

import type { InfoBlock } from "shop-state/types";
import type { NewsPost } from "../NewsListItem";

import React, { useContext, useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { Helmet } from "react-helmet-async";
import { HomeData, AffiliateListSelectedData, CustomerData, CurrentPageInfoData, ViewModeData } from "data";
import ProductCard, { DummyCard } from "components/ProductCard";
import ProductCarousel from "components/ProductCarousel";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import HomeHero from "components/HomeHero";
import NewsListItem from "../NewsListItem";
import Carousel from "components/Carousel";
import Wrapper from "components/Wrapper";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import { getCurrentPageInfo } from "state/current-page-info";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import LogoList from "components/LogoList";
import { Title, Description, Ingress, Items, Item } from "components/UiComponents";
import { getCustomerData, getNumberBasedOnBrowserWidth, tryParseJSONObject } from "helpers/utils";
import Agreement from "components/Agreement";
import { suppliers as suppliersData } from "data/suppliers";
import { useTranslate } from "@awardit/react-use-translate";
import useNotifyMissingAccountDetails from "helpers/use-notify-missing-account-details";
import { logout } from "@crossroads/shop-state/customer";
import { awarditAgreementAgree } from "queries";
import { MODE } from "state/view-mode";

import styles from "./styles.scss";

const HomeView = () => {
  const {
    info,
    routes,
    content: {
      homeview: content,
      allproductsview: { popularCategories, popularCategoriesTitle },
      productCarousel: { useOnHomeView },
    },
    configuration,
  } = useContext(StoreInfoContext);
  const home = useData(HomeData);
  const viewMode = useData(ViewModeData);
  const sendMessage = useSendMessage();
  const affiliateList = useData(AffiliateListSelectedData);
  const t = useTranslate();
  const { width: browserWidth } = useBrowserDimensions();
  const customer = getCustomerData(useData(CustomerData));
  const { partnerId } = info;
  const [showTerms, setShowTerms] = useState(false);
  const isBrowser = useBrowser();
  const client = useClient();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";
  const cookieConsentIsVisible = viewMode === MODE.COOKIE_CONSENT;
  const numAffiliates = getNumberBasedOnBrowserWidth(
    styles,
    { small: 4, medium: 3 },
    4,
    browserWidth
  );
  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];

  useEffect(() => {
    sendMessage(getCurrentPageInfo("HOMEVIEW"));
    sendMessage(getCurrentPageInfo("NEWSVIEW"));
    sendMessage(getCurrentPageInfo("CURRENTINFO6"));
  }, []);

  useEffect(() => {
    if (isBrowser &&
      configuration.showTermsOnFirstLogin === true &&
      customer &&
      customer.awardit.agreementAccepted === false) {
      setShowTerms(true);
    }
  }, [isBrowser, customer, configuration]);

  const postAgreement = async () => {
    await client(awarditAgreementAgree);
  };

  useNotifyMissingAccountDetails();

  const currentInfoOrder = content.currentInfoOrder ?
    Number.parseInt(content.currentInfoOrder, 10) :
    1;
  const earnOnlineOrder = content.earnOnlineOrder ?
    Number.parseInt(content.earnOnlineOrder, 10) :
    2;
  const howToEarnOrder = content.howToEarnOrder ?
    Number.parseInt(content.howToEarnOrder, 10) :
    3;
  const featuredProductsOrder = content.featuredProductsOrder ?
    Number.parseInt(content.featuredProductsOrder, 10) :
    4;
  const additionalHtmlOrder = content.additionalHtmlOrder ?
    Number.parseInt(content.additionalHtmlOrder, 10) :
    5;
  const additionalHtmlSecondaryOrder = content.additionalHtmlSecondaryOrder ?
    Number.parseInt(content.additionalHtmlSecondaryOrder, 10) :
    6;
  const popularProductsOrder = content.popularProductsOrder ?
    Number.parseInt(content.popularProductsOrder, 10) :
    -1;
  const popularCategoriesOrder = content.popularCategoriesOrder ?
    Number.parseInt(content.popularCategoriesOrder, 10) :
    -1;
  const currentInfo6Order = content.currentInfo6Order ?
    Number.parseInt(content.currentInfo6Order, 10) :
    -1;
  const getItemAmount = (config, defaultAmount) => {
    if (browserWidth > 0) {
      const currentBreakpoint = Object.keys(config).find(breakpoint => {
        return browserWidth <= parseInt(styles[breakpoint], 10);
      });

      return currentBreakpoint ? config[currentBreakpoint] : defaultAmount;
    }

    return 0;
  };

  const newsCurrentInfo = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.newsview &&
    currentPageInfoData.data.newsview.length > 0 ? currentPageInfoData.data.newsview : [];

  const newsCurrentInfoChunks: Array<Array<InfoBlock>> = [];

  if (currentPageInfoData.state === "LOADED" && currentPageInfoData.data.newsview && currentPageInfoData.data.newsview.length > 0) {
    for (let i = 0; i < newsCurrentInfo.length; i += 3) {
      newsCurrentInfoChunks.push(newsCurrentInfo.slice(i, i + 3));
    }
  }

  const featuredProductsAmount = getItemAmount({ small: 2, medium: 3 }, 4);

  const featuredProducts = useMemo(() => home.state !== "LOADED" ? [...new Array(4)] :
    home.data.featuredProducts && home.data.featuredProducts.filter(p => !memberTargetList ||
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId)
      .sort(() => 0.5 - Math.random())
      .slice(0, featuredProductsAmount
      ), [home.state, featuredProductsAmount]);

  const affiliateItems = (affiliateList.state === "LOADED" ? affiliateList.data : []).slice(0, numAffiliates);

  const blockAmount = content.currentInfo1Count ?
    Number.parseInt(content.currentInfo1Count, 10) : 0;

  const currentInfoTopArray = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.homeview &&
    blockAmount > 0 ?
    currentPageInfoData.data.homeview.slice(0, blockAmount) : [];

  const currentInfoBottomArray = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.homeview &&
    currentPageInfoData.data.homeview.length > blockAmount ?
    currentPageInfoData.data.homeview.slice(blockAmount) : [];

  const currentInfo6Count = content.currentInfo6Count ?
    Number.parseInt(content.currentInfo6Count, 10) : 0;

  const currentInfo6Array = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.currentinfo6 &&
    currentPageInfoData.data.currentinfo6.length > 0 ?
    (currentInfo6Count > 0 ?
      currentPageInfoData.data.currentinfo6.slice(0, currentInfo6Count) :
      currentPageInfoData.data.currentinfo6) : [];

  const earnOnlineSection = (
    <div key="earnOnline" className={styles.section}>
      <div className={styles.top}>
        <Ingress className={styles.ingress}>
          {content.earnOnlineHeading &&
            <Title className={styles.title} elem="h2">{content.earnOnlineHeading}</Title>
          }
          {content.earnOnlineDescription &&
            <Description className={styles.description} content={content.earnOnlineDescription} />
          }
        </Ingress>
        {content.earnOnlineButtonLink && content.earnOnlineButtonText &&
        <div className={styles.earnOnline}>
          <Link
            to={content.earnOnlineButtonLink}
            className={cn("link", styles.cta)}
          >
            {content.earnOnlineButtonText}
          </Link>
        </div>
        }
      </div>
      {affiliateList.state === "LOADED" &&
        <AffiliateList items={affiliateItems} />
      }

      {affiliateList.state === "LOADING" &&
        <AffiliateDummyList items={Array.from({
          length: numAffiliates,
        }, () => null)} />
      }
    </div>
  );

  const featuredProductsSection = (
    <div key="featuredProducts" className={styles.section}>
      <div className={styles.top}>
        {(content.featuredProductsHeading || content.featuredProductsDescription) && (
          <Ingress className={styles.ingress}>
            {content.featuredProductsHeading && (
              <Title className={styles.title} elem="h2">{content.featuredProductsHeading}</Title>
            )}
            {content.featuredProductsDescription && (
              <Description
                className={styles.description} content={content.featuredProductsDescription} />
            )}
          </Ingress>
        )}
        {content.featuredProductsButtonLink && content.featuredProductsButtonText &&
          <div className={styles.featuredProducts}>
            <Link
              to={content.featuredProductsButtonLink}
              className={cn("link", styles.cta)}
            >
              {content.featuredProductsButtonText}
            </Link>
          </div>
        }
      </div>
      <Items>
        {featuredProducts && featuredProducts.map((p, i) =>
          p ?
            <Item key={p.name} className="awardit-homeViewProductCard">
              <ProductCard product={p} list={content.featuredProductsHeading ?? ""} position={i} />
            </Item> :
            <Item key={i}><DummyCard /></Item>
        )}
      </Items>
    </div>
  );

  const howToEarnSection =
  (content.howToEarnDescription || content.howToEarnHeading || content.howToEarnImage) ? (
    <div key="howToEarn" className={styles.promotion}>
      <div className={cn(styles.left, !content.howToEarnImage ? styles.empty : "")}>
        {content.howToEarnImage &&
          <img src={content.howToEarnImage} />
        }
      </div>
      <div className={styles.right}>
        {content.howToEarnHeading &&
          <Title className={styles.title} elem="h2">{content.howToEarnHeading}</Title>
        }
        {content.howToEarnDescription &&
          <Description className={styles.description} content={content.howToEarnDescription} />
        }

        {content.howToEarnButtonLink && content.howToEarnButtonText &&
          <Link
            to={content.howToEarnButtonLink}
            className={cn("link", styles.ctaLarge)}
          >
            {content.howToEarnButtonText}
          </Link>
        }
      </div>
    </div>
  ) : null;

  const featuredContentSection = (
    <div key="featuredContent">
      <div className={styles.currentPageInfoTop}>
        {currentPageInfoData.state === "LOADED" && currentInfoTopArray.length > 0 && (
        /* eslint-disable react/no-danger */
          <div className={styles.currentInfo}>
            {currentInfoTopArray.map<React$Node>((info, i) => (
              <div key={"offerlist_item_" + (info.id ? info.id : "")} className={styles.currentInfoCardWrapper} idx={i}>
                <div className={styles.currentInfoCard}>
                  <div className={styles.currentInfoCardImageWrapper}>
                    <img src={info.image} />
                  </div>
                  {(info.title || info.content) && (
                    <div className={styles.currentInfoCardContent}>
                      {info.title && (
                        <h2>{info.title}</h2>
                      )}
                      {info.content && (
                        <p
                          className={styles.currentInfoDescription}
                          dangerouslySetInnerHTML={{ __html: info.content }} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )
        /* eslint-enable react/no-danger */
        }
        {content.motivationHeroNewsStatus !== undefined &&
        content.motivationHeroNewsStatus !== null &&
        content.motivationHeroNewsStatus === true &&
        currentPageInfoData.state === "LOADED" &&
        currentPageInfoData.data.newsview &&
        currentPageInfoData.data.newsview.length > 0 && (
          <div className={styles.newsListWrapper}>
            {routes.newsListView && routes.newsListView.title &&
              <h2>{routes.newsListView.title}</h2>
            }
            {/* Show three items per slide, max 15 items */}
            <Carousel
              autoplay
              className={styles.carousel}
              items={newsCurrentInfoChunks.slice(0, 5)
                .map((x, i) => (
                  <Item key={`newsListItem${i}`} className={styles.item}>
                    {x.map(x => ({ ...x, link: routes.newsListView && routes.newsListView.url && x.id ? `${routes.newsListView.url}#newsItem-${x.id}` : "" }))
                      .map((x: NewsPost) => (
                        <NewsListItem key={x.title} item={x} />
                      ))}
                  </Item>
                ))
              }
              slidesToScroll={1}
              slidesToShow={1}
              timer={2500}
            />
            {routes.newsListView && routes.newsListView.url && (
              <Link className={styles.newsListLink} to={routes.newsListView.url}>
                {t("NEWS.SHOW_ALL")}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const currentPageInfo = (
    currentPageInfoData.state === "LOADED" && currentInfoBottomArray.length > 0 && (
      <div key="currentPageInfo" className={styles.currentPageInfoBottom}>
        {/* eslint-disable react/no-danger */}
        {content.currentInfoTitle &&
          <Title className={styles.title} elem="h2">{content.currentInfoTitle}</Title>
        }
        <div className={styles.currentInfo}>
          {currentInfoBottomArray.map<React$Node>((info, i) => (
            <div key={"offerlist_item_" + i} className={styles.currentInfoCardWrapper} idx={i}>
              <div className={styles.currentInfoCard}>
                <div>
                  <img src={info.image} />
                </div>
                <div className={styles.currentInfoCardContent}>
                  <h2>{info.title}</h2>
                  <p
                    className={styles.currentInfoDescription}
                    dangerouslySetInnerHTML={{ __html: info.content }} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );
  const popularProductsSection = (
    Boolean(useOnHomeView) && (
      <div key="popularProductsSection" className={styles.section}>
        <ProductCarousel className={styles.productCarousel} />
      </div>
    )
  );
  const popularCategoriesSection = (
    <div key="popularCategories" className={styles.section}>

      {popularCategoriesJson &&
        <PopularCategoryCarousel
          title={popularCategoriesTitle ?? ""}
          popularCategories={popularCategoriesJson}
        />
      }
    </div>
  );
  const additionalHtmlSection = (
    content.additionalHtml && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtml }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const additionalHtmlSecondarySection = (
    content.additionalHtmlSecondary && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtmlSecondary }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const suppliers = (
    suppliersData && suppliersData[partnerId] && (
      <div key="suppliers" className={styles.suppliers}>
        {routes.suppliersView && routes.suppliersView.title && (
          <Title className={styles.title} elem="h2">{routes.suppliersView.title}</Title>
        )}
        <LogoList logos={suppliersData[partnerId]} />
      </div>
    )
  );

  const currentInfo6Section = (
    currentInfo6Array &&
    currentInfo6Array.length > 0 &&
    routes.currentInfoView6?.url && (
      <div className="awardit-currenInfoCardsWrapper">
        {content.currentInfo6Title &&
          <Title className={styles.title} elem="h2">{content.currentInfo6Title}</Title>
        }
        <div className={styles.currenInfoCardsSection}>
          {currentInfo6Array.map((info, i) => (
          /* eslint-disable react/no-danger */
            <div key={"currentinfo6_item_" + i} className={styles.currenInfoCardsSectionCardWrapper} idx={i}>
              <Link
                to={{
                  pathname: `${(routes.currentInfoView6 && routes.currentInfoView6.url) ?? ""}/${info.id}`,
                  state: { hint: { title: info.title } },
                }}
                className={cn(styles.currentInfo6Card, "awardit-currenInfoCard")}
              >
                {info.title && <h3>{info.title}</h3>}
                {info.image &&
                  <div className={styles.currenInfo6CardImage}>
                    <img src={info.image} />
                  </div>
                }
                {info.content &&
                  <div
                    className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                    dangerouslySetInnerHTML={{ __html: info.description }}
                  />
                }
              </Link>
            </div>
          /* eslint-enable react/no-danger */
          ))}
        </div>
      </div>
    )
  );

  const main = [];

  if (earnOnlineOrder >= 0) {
    main[earnOnlineOrder] = earnOnlineSection;
  }

  if (howToEarnOrder >= 0) {
    main[howToEarnOrder] = howToEarnSection;
  }

  if (featuredProductsOrder >= 0) {
    main[featuredProductsOrder] = featuredProductsSection;
  }

  if (currentInfoOrder >= 0) {
    main[currentInfoOrder] = currentPageInfo;
  }

  if (popularProductsOrder >= 0) {
    main[popularProductsOrder] = popularProductsSection;
  }

  if (popularCategoriesOrder >= 0) {
    main[popularCategoriesOrder] = popularCategoriesSection;
  }

  if (additionalHtmlOrder >= 0) {
    main[additionalHtmlOrder] = additionalHtmlSection;
  }

  if (additionalHtmlSecondaryOrder >= 0) {
    main[additionalHtmlSecondaryOrder] = additionalHtmlSecondarySection;
  }

  if (currentInfo6Order >= 0) {
    main[currentInfo6Order] = currentInfo6Section;
  }

  return (
    <div className={cn(styles.block, "awardit-homeView")}>
      <Helmet
        title={content.pageTitle ?? ""}
      />
      <HomeHero>
        {featuredContentSection}
      </HomeHero>
      <Wrapper className={cn(styles.wrapper, "awardit-homeViewWrapper")}>
        {main}
        {suppliers}
      </Wrapper>

      {showTerms && !cookieConsentIsVisible &&
        <Agreement
          agreementModalOpen={showTerms}
          setAgreementModalOpen={open => {
            if (open === true) {
              sendMessage(logout());
            }

            if (open === false) {
              postAgreement();
              setShowTerms(open);
            }
          }}
        />
      }
    </div>
  );
};

export default HomeView;
